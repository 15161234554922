<template>
  <div class="avatar-wrap">
    <el-avatar :style="`background:${isBroker ? background[2] :background[Math.floor(Math.random()*4)]};font-size: ${size === 64 ? 24 : 18}px;`"
               :size="size">{{ singleName }}</el-avatar>
  </div>
</template>

<script>
export default {
  props: {
    isBroker: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 64
    }
  },
  data () {
    return {
      background: ['#FFCD56', '#FF8B6A', '#83BDFC', '#FF4B56', '#57D64C']
    }
  },
  computed: {
    singleName () {
      let sname = ''
      if (this.name.indexOf('营') > -1) {
        sname = this.name.substr(3)
      } else {
        sname = this.name
      }
      return sname.trim().split('')[0]
    }
  }
}
</script>

<style lang="less" scoped>
</style>